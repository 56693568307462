import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.brown};
  background: #fcf8e3;
  padding: 15px;
  display: flex;
  align-content: center;
  align-items: center;
`;

export const Icon = styled.div`
  color: ${({ theme }) => theme.colors.brown};
  width: 20px;
  height: 20px;
`;

export const Message = styled.span`
  margin: 0 8px;
`;
